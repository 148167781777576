import { ObjectType, ShapeType } from 'cvat-core/src/enums';
import { getCore, Attribute } from 'cvat-core-wrapper';
import ObjectState, { SerializedData } from 'cvat-core/src/object-state';
import { isEqual } from 'lodash';

export const MMSI_KEY = 'mmsi';
export const CONNECTOR_KEY = 'connector';
const CLASS_KEY = 'class';
const DIST_KEY = 'dist';
const cvat = getCore();

/** For orca
 * Find a center of rectangle.
 */
export const findCenterOfRectangle = (rectPoints: Array<number>): { centerRectX: number; centerRectY: number } => {
    const centerRectX = (rectPoints[0] + rectPoints[2]) / 2;
    const centerRectY = (rectPoints[1] + rectPoints[3]) / 2;

    return { centerRectX, centerRectY };
};

const isPointInRects = (x: number, y: number, rectPoints: number[]): boolean => {
    if (x >= rectPoints[0] && x <= rectPoints[2] && y >= rectPoints[1] && y <= rectPoints[3]) {
        return true;
    }

    return false;
};

// Get the value of given key on point object.
export const getPointValue = (selectedPoint: SerializedData, key: string): string => {
    const pointLabelAttributes = selectedPoint?.label?.attributes ?? [];
    const pointAttributes = selectedPoint?.attributes ?? {};
    const mmsiPointId = pointLabelAttributes?.find((x: { name: string }) => x.name === key)?.id;
    return mmsiPointId ? pointAttributes[mmsiPointId] : '';
};

// Get the value of mmmsi key on shape object.
export const getMmsiValueFromShape = (shape: SerializedData): string => {
    const shapeLabelAttributes = shape?.label?.attributes ?? [];
    const shapeAttributes = shape?.attributes ?? {};
    const mmsiShapeId = shapeLabelAttributes?.find((x: { name: string }) => x.name === MMSI_KEY)?.id;
    return mmsiShapeId ? shapeAttributes[mmsiShapeId] : '';
};

// Get shape for given value, and given type from saved states.
export const getShapeFromStates = (
    shapeType: string,
    value: string,
    states: SerializedData[],
): SerializedData | undefined => states?.find((state) => {
    const mmsiID = (state?.label?.attributes ?? [])?.find(
        (attribute: { name: string }) => attribute.name === MMSI_KEY,
    )?.id;
    const mmsiValue = mmsiID && state.attributes ? state.attributes[mmsiID] : 0;
    return state.shapeType === shapeType && Number(mmsiValue) === Number(value);
});

// Copy Attribute for given key, and return the output attribute.
export const copyAttributeValue = (
    labelAttributes: Attribute[],
    key: string,
    value: string,
): Record<number, string> => {
    const attr: Record<number, string> = {};
    const id = Number(labelAttributes?.find((x: { name: string }) => x.name === key)?.id || 0);
    if (id) {
        attr[id] = value;
    }
    return attr;
};

// Copy Point attribute for given key, and return the output attribute.
const copyPointAttributeValue = (
    labelAttributes: Attribute[],
    key: string,
    selectedPoint: SerializedData,
): Record<number, string> => {
    const pointValue = getPointValue(selectedPoint, key);
    const attr = copyAttributeValue(labelAttributes, key, pointValue);
    return attr;
};

// Copy all point attributes to output attribute.
const copyAllPoinFields = (selectedPoint: SerializedData, attributes: Attribute[]): Record<number, string> => {
    const mmsiAttr = copyPointAttributeValue(attributes, MMSI_KEY, selectedPoint);
    const classAttr = copyPointAttributeValue(attributes, CLASS_KEY, selectedPoint);
    const distAttr = copyPointAttributeValue(attributes, DIST_KEY, selectedPoint);
    return {
        ...mmsiAttr,
        ...distAttr,
        ...classAttr,
    };
};

// Get object state.
export const getStateObject = (state: SerializedData, frame: number, selectedPoint: SerializedData): SerializedData => {
    state.objectType = state.objectType || ObjectType.SHAPE;
    state.label = state.label || {};
    state.frame = frame;
    state.rotation = state.rotation || 0;
    state.occluded = state.occluded || false;
    state.outside = state.outside || false;
    const rectAttributes = state?.label?.attributes ?? [];
    const attributes = copyAllPoinFields(selectedPoint, rectAttributes);
    state.attributes = attributes;
    const objectState = new cvat.classes.ObjectState(state);
    return objectState;
};

// Get shapes for given type from saved states.
export const getShapesFromStates = (shapeType: string,
    states: SerializedData[]): SerializedData[] | undefined => states?.filter((state) => {
    const mmsiID = (state?.label?.attributes ?? [])?.find(
        (attribute: { name: string }) => attribute.name === MMSI_KEY,
    )?.id;
    const mmsiValue = mmsiID && state.attributes ? state.attributes[mmsiID] : 0;
    return state.shapeType === shapeType && Number(mmsiValue);
});

const getPolyLineShape = (
    states: SerializedData[],
    shape: SerializedData,
    index0: number,
    index1: number,
): SerializedData | undefined => {
    const connecters = states.filter(
        (state) => state.shapeType === ShapeType.POLYLINE &&
            state?.points &&
            isPointInRects(state.points[index0], state.points[index1], shape?.points ?? []),
    );

    const connecter = connecters.find(
        (x) => !states.some(
            (state) => state.shapeType === ShapeType.POINTS &&
                    x.points &&
                    isEqual([x.points[index0], x.points[index1]], state.points),
        ),
    );

    return connecter;
};
// Get connecter from saved states.
export const getConnecterShape = (shape: SerializedData, states: SerializedData[]): SerializedData | undefined => {
    let connecter = getPolyLineShape(states, shape, 0, 1);

    if (!connecter) {
        connecter = getPolyLineShape(states, shape, 2, 3);
    }
    return connecter;
};

export const getRectangleShape = (states: ObjectState[], polylinePoints: number[]): ObjectState => {
    let rectangle = states.find(
        (state) => state.shapeType === ShapeType.RECTANGLE &&
            isPointInRects(polylinePoints[0], polylinePoints[1], state.points ?? []),
    );
    const pointShape = states.find(
        (state) => state.shapeType ===
        ShapeType.POINTS && isEqual([polylinePoints[0], polylinePoints[1]], state.points),
    );
    if (pointShape || !rectangle) {
        rectangle = states.find(
            (state) => state.shapeType === ShapeType.RECTANGLE &&
                isPointInRects(polylinePoints[2], polylinePoints[3], state.points ?? []),
        );
    }
    return rectangle ?? ({} as ObjectState);
};

export const getUpdatedState = (state: ObjectState, value: string, key: string): ObjectState => {
    const rectAttributes = state?.label?.attributes ?? [];
    const attr = copyAttributeValue(rectAttributes, key, value);
    state.attributes = attr;
    return state;
};
